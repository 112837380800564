export const LOCALSTORAGE_TOKEN_KEY = 'online_event_token';
export const PRIMARY_COLOR = '#042851';

export const FUNCTIONS = {
  ENABLE_QUIZ: 'online_event_backend.conference.tasks.activate_quiz',
  DISABLE_QUIZ: 'online_event_backend.conference.tasks.deactivate_quiz',
  ENABLE_DAY:
    'online_event_backend.conference.tasks.activate_conference_setting',
  ENABLE_SETTING: 'online_event_backend.conference.tasks.activate_setting',
  DISABLE_DAY:
    'online_event_backend.conference.tasks.deactivate_conference_setting',
  DISABLE_SETTING: 'online_event_backend.conference.tasks.deactivate_setting'
};

export const FUNCTION_SELECT = {
  [FUNCTIONS.ENABLE_QUIZ]: 'Enable Quiz',
  [FUNCTIONS.DISABLE_QUIZ]: 'Disable Quiz',
  [FUNCTIONS.ENABLE_DAY]: 'Enable Day',
  [FUNCTIONS.ENABLE_SETTING]: 'Enable Setting',
  [FUNCTIONS.DISABLE_DAY]: 'Disable Day',
  [FUNCTIONS.DISABLE_SETTING]: 'Disable Setting'
};

export const ARGS = {
  DAY_CHAT: "('enable_conference_chat',)",
  LOGIN: "('login',)"
};

export const DAY_ARGS = {
  DAY_CHAT: 'Chat'
};

export const SETTINGS_ARGS = {
  LOGIN: 'Login'
};
