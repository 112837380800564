import { notification } from 'antd';
import { lazy } from 'react';

function retry(fn, retriesLeft = 2, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        console.log(error);
        setTimeout(() => {
          if (retriesLeft === 1) {
            notification.error({
              message: 'There was an error loading an element',
              description: 'attempting a full refresh to recover'
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default function lazyLoad(importModuleName) {
  return lazy(() => retry(() => importModuleName()));
}
