// import './wydr';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import './styles/index.scss';
import './styles/fonts.css';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './i18n';

import './errorReporting';

import SpinnerPage from './components/SpinnerPage';
import { SettingsProvider } from './contexts/settings';
import lazyLoad from './libs/retryOrReload';

const NotFoundPage = lazyLoad(() => import(() => import('./pages/404')));
const IndexPage = lazyLoad(() => import('./pages'));
const App = lazyLoad(() => import('./pages/app'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SpinnerPage />}>
      <SettingsProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/404" component={NotFoundPage} />
            <Route path="/app/" component={App} />
            <Route path="/" exact component={IndexPage} />
            <Route path="*" render={() => <Redirect to="/404" />} />
          </Switch>
        </BrowserRouter>
      </SettingsProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
