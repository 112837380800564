import qs from 'query-string';
import i18next from 'i18next';
import { LOCALSTORAGE_TOKEN_KEY } from '../constants';

async function client(
  endpoint,
  { body, multipart = false, queryParams, ...customConfig } = {}
) {
  const token = window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  const headers = { 'content-type': 'application/json' };
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  let query = '';
  if (queryParams) {
    query += `?${qs.stringify(queryParams)}`;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...(!multipart ? headers : {}),
      ...customConfig.headers
    }
  };
  if (body && !multipart) {
    config.body = JSON.stringify(body);
  } else if (body && multipart) {
    const data = new FormData();
    Object.keys(body).forEach(k => data.append(k, body[k]));
    config.body = data;
  }
  let result = null;
  try {
    result = await window.fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}${query}`,
      config
    );
  } catch (e) {
    result = {
      error: true,
      data: { message: i18next.t('connection-error') }
    };
  }
  let resBody = null;
  if (result.status !== 204) {
    resBody = await result.json();
  }

  if (result.status === 401) {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
  }

  return { data: resBody, error: result.status > 300 ? result.status : false };
}

export default client;
